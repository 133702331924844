











import vtable from '@/components/table.vue';
import { mapState } from 'vuex';
import { RootState } from '@/store';

export default {
	name: 'tables',
	components: {
		vtable
	},
	computed: mapState({
		codes: (state) => (state as RootState).projectCurrent.codes,
		categories: (state) => (state as RootState).projectCurrent.categories,
		sources: (state) => (state as RootState).projectCurrent.sources,
		fileCategories: (state) =>
			(state as RootState).projectCurrent.fileCategories,
		fileCodes: (state) => (state as RootState).projectCurrent.fileCodes,
		cases: (state) => (state as RootState).projectCurrent.cases
	})
};
